import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useState } from 'react';
import { DragAndDrop } from '../../../../components/drag-and-drop/drag-and-drop';
import { FileInputButton } from '../../../../components/file-input-button/file-input-button';
import { InApp } from '../../../../components/in-app/in-app';
import RightMenu from '../../../../components/menu/right-menu/right-menu';
import { RangeSlider } from '../../../../components/slider/range-slider';
import GDHToast from '../../../../components/toast/toast';
import { PdfService } from '../../../../services/pdf-service';
import { pdfjs } from 'react-pdf';
import { ERROR_MAX_ONE_FILE } from '../../../../utils/constants';

import "./pdf-split.css";
import { StorageUtils } from '../../../../utils/storage-utils';
import DataService from '../../../../services/data-service';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const SPLIT_SUCCESS_MSG = "La division est finie. Vous pouvez consulter le fichier";
const SPLIT_ERROR_MSG = "Un erreur s'est produite pendant la division du fichier";


export function PdfSplit(props: any) {

    const [_files, _setFiles] = useState<File[]>([]);
    const [_showToast, _setShowToast] = useState<boolean>(false);
    const [_success, _setSuccess] = useState<boolean>(false);
    const [_toastMessage, _setToastMessage] = useState<string>("");
    const [_openButtonEnabled, _setOpenButtonEnabled] = useState<boolean>(false);
    const [_isPrimaryLoading, _setIsPrimaryLoading] = useState<boolean>(false);
    const [_numPages, _setNumPages] = useState<number>(2);
    const [_values, _setValues] = useState([1, 1]);

    /*
     * The handler for click on the delete buton of each file
     */
    const onClickDeleteFile = (filename: string) => {
        const remainingFiles = _files.filter((file: any) => {
            return file.name !== filename;

        })
        _setFiles(remainingFiles);
    }

    /*
     * This fuctions is the handler for click event on convert button
     */
    const onClickConvertButton = async () => {
        if (_files.length !== 1) {
            // cannot merge less than two files
            _setToastMessage(ERROR_MAX_ONE_FILE);
            _setSuccess(false);
            _setShowToast(true);
            return;
        }

        _setOpenButtonEnabled(false);
        _setIsPrimaryLoading(true);
        let toastMessage;
        let success;
        try {
            await PdfService.split(_files[0], _values[0] + "-" + _values[1]);
            const userId: string | null = await StorageUtils.getInstance().getUserId()
            if (userId) {
                const users = await DataService.getEmployeeInfo(userId)
                let actualUser = null

                if (users.length > 0) {
                    actualUser = users[0]
                    let filesString: any[] = []
                    _files.filter((file: any) => {
                        filesString.push(file.name)
                    })
                }
            }
            toastMessage = SPLIT_SUCCESS_MSG;
            success = true;
        } catch (error) {
            console.log(error);
            toastMessage = SPLIT_ERROR_MSG;
            success = false;
        }
        _setOpenButtonEnabled(success);

        _setToastMessage(toastMessage);
        _setSuccess(success);
        _setShowToast(true);
        _setIsPrimaryLoading(false);
    }

    /*
        * This fuctions is the handler for click event on open button
        */
    const onClickOpenButton = () => {
        PdfService.open();
    }

    /*
     * Handler for toast close event
     */
    const handleToastClose = () => {
        _setShowToast(false);
    }

    /*
     * Handler for slider value change
     */
    const handleSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
        if (!Array.isArray(value)) {
            console.error("value is not an array");
            return;
        }
        _setValues(value);
    };

    /*
     * Handler for droped file error
     */
    const onDropedFileError = (errorMsg: string) => {
        _setShowToast(true);
        _setSuccess(false);
        _setToastMessage(errorMsg);
    }

    /*
     * Handler for droped file success
     */
    const onDropedFiles = (files: File[]) => {
        addFilesToList(files);
    }

    /*
 * This function is called when one or several new files are imported
 */
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files || !event.target.files[0]) {
            return;
        }

        const filesArray: File[] = Object.values(event.target.files);

        addFilesToList(filesArray);
    }

    /*
     * Check if there is no several occurence of the same file and add files to the selected files
     */
    const addFilesToList = async (filesToAdd: File[]) => {

        if (filesToAdd.length > 1 || _files.length > 0) {
            _setShowToast(true);
            _setSuccess(false);
            _setToastMessage(ERROR_MAX_ONE_FILE);
            return;
        }

        // to get the number of pages of the pdf
        let fileReader = new FileReader();
        fileReader.onload = function (this: FileReader, ev: ProgressEvent<FileReader>) {
            // turn array buffer into typed array
            let typedarray = new Uint8Array(this.result as ArrayBuffer);
            const loadingTask = pdfjs.getDocument(typedarray);
            loadingTask.promise.then((pdf: any) => {
                _setNumPages(pdf._pdfInfo.numPages)
            });
        };
        fileReader.readAsArrayBuffer(filesToAdd[0]);

        _setFiles([..._files, ...filesToAdd]);
    }

    return (
        <InApp
            isPrimaryLoading={_isPrimaryLoading}
            isSecondaryLoading={false}
            backUrl="/PDF/home"
            title="PDF Utils - Diviser"
            displayMenuButton={false}
            showRightMenuBtn={true}
            rightMenu={<RightMenu />}
            refresh={null}
        >
            <div className="pdf-merge-container">
                <div className="pdf-merge-button-container">
                    <FileInputButton
                        disabled={_files.length > 0}
                        onInputChange={onInputChange}
                        acceptedExtentions=".pdf"
                    />
                </div>

                <DragAndDrop
                    acceptedExtention={["pdf"]}
                    errorCallback={onDropedFileError}
                    isEmpty={_files.length === 0}
                    dropedFilesCallback={onDropedFiles}
                >
                    <IonList>
                        {
                            _files.map((file: File) => {
                                return (
                                    <IonItem key={file.name}>
                                        <IonLabel>{file.name}</IonLabel>
                                        <div slot="end" onClick={() => onClickDeleteFile(file.name)} className="pdf-merge-delete-button-container">
                                            <i className="far fa-trash-alt"></i>
                                        </div>
                                    </IonItem>
                                )
                            })
                        }
                    </IonList>
                </DragAndDrop>

                <div className="pdf-split-slider-container">
                    <RangeSlider
                        minValue={1}
                        maxValue={_numPages}
                        values={_values}
                        onChange={handleSliderChange}
                        disabled={_files.length !== 1}
                    />
                </div>

                <div className="pdf-split-button-container">
                    <IonButton disabled={_files.length !== 1} expand="block" onClick={onClickConvertButton}>
                        Diviser
                    </IonButton>
                    <IonButton disabled={!_openButtonEnabled} expand="block" onClick={onClickOpenButton}>
                        Ouvrir
                    </IonButton>
                </div>
            </div>


            <GDHToast
                show={_showToast}
                handleClose={handleToastClose}
                success={_success}
                content={_toastMessage}
            />
        </InApp>
    )
}